import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CancelRequestService {
  private cancelPendingRequests$ = new Subject<void>();

  // Emit value to cancel pending requests
  cancelPendingRequests() {
    this.cancelPendingRequests$.next();
  }

  // Return the cancellation observable
  onCancelPendingRequests() {
    return this.cancelPendingRequests$.asObservable();
  }
}
