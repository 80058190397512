<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm.svg" alt="" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo.svg" alt="" />
          </span>
        </a>

       
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>
    </div>

    <div class="d-flex rightHeader">
      <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-search-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ...">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ml-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ml-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>        
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="mr-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>       
        </div>
      </div> -->

  

      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button type="button" class="btn header-item noti-icon waves-effect" (click)="fullscreen()">
          <i class="ri-fullscreen-line"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block notifications-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect roundedIconHeader"
          id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img src="assets/images/notification-icon.svg">
          <span class="noti-dot"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <ng-scrollbar style="position: relative; height: 230px;">
            <a href="" class="text-reset notification-item">

            </a>
      
          </ng-scrollbar>
        </div>
      </div>

      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect " id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="roundedIconHeader"><img class="header-profile-user" src="assets/images/user-icon.svg" alt=""></div>       
         
          <h6 class="nameUser d-inline-block">Kevin</h6>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i> 
        </button>

        <div class="dropdown-menu" ngbDropdownMenu placement="bottom-left">
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);"><i class="ri-user-line align-middle mr-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
     
          
  
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>
     

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect"
          (click)="toggleRightSidebar()">
          <i class="ri-settings-2-line"></i>
        </button>
      </div> -->

    </div>
  </div>
</header>
