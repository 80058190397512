import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  

  constructor(
    private http: HttpClient, 
    private router: Router
  ) { }

  canActivate(): boolean {
    const token: any = localStorage.getItem("token");
    // this.userData = JSON.parse(localUser);
    if (token) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  };


  signup(body: any): Observable<any> {
    return this.http.post<any>(`${environment.authApiUrl}auth/signup`, body).pipe(
      catchError(this.handleError)
    );
  }

  resetPassword(body: any): Observable<any> {
    return this.http.post<any>(`${environment.authApiUrl}auth/reset-password`, body).pipe(
      catchError(this.handleError)
    );
  }

  login(body: any): Observable<any> {
    return this.http.post<any>(`${environment.authApiUrl}auth/login`, body).pipe(
      catchError(this.handleError)
    );
  }

  logout(){
    localStorage.clear();
    this.router.navigate(['/login']);
    return true;
  }

  handleError(error: HttpErrorResponse) {
    let msg:any = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = error.error;
    }
    return throwError(msg);
  }
}
