import { Component, OnInit } from '@angular/core';
import { SocketService } from './shared/services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'geo-traking';

  constructor(
    private socketService: SocketService
  ){
    this.socketService.deviceDetected.subscribe((data: any) => {
      // console.log("socket", data);
      this.socketService.emitDeviceDetected(data);
    });
  }

  ngOnInit(): void {
    
  }
}
