import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    // {
    //     id: 2,
    //     label: 'MENUITEMS.DASHBOARDS.TEXT',
    //     img:"assets/images/dashboard-icon.svg",
    //     link: '/dashboard'
    // },
    // {
    //     id: 3,
    //     label: 'MENUITEMS.ORGANIZATION.TEXT',  
    //     img:"assets/images/organizations-icon.svg",
    //     link: '/organization'
    // },
    // {
    //     id: 4,
    //     label: 'MENUITEMS.BRANCH.TEXT',
    //     img:"assets/images/branches-icon.svg",
    //     link: '/branches'
    // },

    // {
    //     id:5,
    //     label: 'MENUITEMS.GATWAY.TEXT',
    //     img:"assets/images/gateway-icon.svg",
    //     link: '/gatway'
    // },

    // {
    //     id: 4,
    //     label: 'MENUITEMS.STUDENTS.TEXT',
    //     img:"assets/images/students-icon.svg",
    //     link: '/students'
    // },


    // {
    //     id: 11,
    //     label: 'MENUITEMS.BRANCHUSER.TEXT',
    //     img:"assets/images/branches-icon.svg",
    //     link: 'branches-user'
    // },

    {
        id: 12,
        label: 'Dashboard',
        img:"assets/images/branches-icon.svg",
        link: 'dashboard'
    },
    {
        id: 16,
        label: 'Manage Gateway',
        img:"assets/images/branches-icon.svg",
        link: 'manage-gateway'
    },

    {
        id: 13,
        label: 'Manage Class',
        img:"assets/images/branches-icon.svg",
        link: 'manage-class'
    },
    {
        id: 14,
        label: 'Manage Student',
        img:"assets/images/branches-icon.svg",
        link: 'manage-student'
    },
    {
        id: 15,
        label: 'Attendance',
        img:"assets/images/branches-icon.svg",
        link: 'attendance'
    },







  
];
