import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

// import { Socket } from 'ngx-socket-io';
import { io, Socket } from 'socket.io-client';
@Injectable({
  providedIn: 'root'
})
export class SocketService {

  // deviceDetected = this.socket.fromEvent('deviceDetected'); // Subscribe to device updates
  private socket: Socket;
  private serverUrl = 'http://alb-dev2-attendance-2075186941.me-central-1.elb.amazonaws.com'; 
  // private serverUrl = 'ws://3.29.50.70:3002'; 
  // private serverUrl = 'ws://localhost:3002'; 

  // authApiUrl : 'http://3.28.39.160/api/'
  // Backend server URL
  // private serverUrl = 'ws://51.112.111.172'; // Backend server URL

  
  //private serverUrl = 'http://172.29.224.1:3000';
    // Subject to emit incoming data
    private deviceDataSubject = new BehaviorSubject<any>(null);
    public deviceData$ = this.deviceDataSubject.asObservable();

  // Observable to emit incoming data
  public deviceDetected = new Subject<any>();

  constructor(
    // private socket: Socket

  ) {
    this.socket = io(this.serverUrl);
    // Listen for 'deviceDetected' event from server
    this.socket.on('deviceDetected', (data: any) => {
      console.log('connected')
      this.deviceDataSubject.next(data);
      this.deviceDetected.next(data); // Emit the data to subscribers
  
    });

  }

    // Send events to server if needed
    emitDeviceDetected(data: any): void {
      this.socket.emit('deviceDetected', data);
    }
  
    // Disconnect from socket when done
    disconnect(): void {
      this.socket.disconnect();
    }
  // Example method to trigger attendance sync manually
  // syncAttendance() {
  //   this.socket.emit('syncAttendance');
  // }
}
