import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router:Router,
    private auth: AuthService,
    ){

  }


  canActivate(): boolean {
    console.log("canActive", this.auth.canActivate())
    if (!this.auth.canActivate()) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

}
