import { Inject, Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, takeUntil, tap, timeout } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CancelRequestService } from './cance-request.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { NotificationService } from './notification.service';


export const apiWithoutHeader: string[] = [];

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // private readonly urlsToNotUse: string[] = [
  //   'search-builddev-v6ptk45ug6fwldsp667i2pda5i',
  // ];

  constructor(
    private injector: Injector,
    private auth: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private cancelRequestService: CancelRequestService,
    private toast: NotificationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = 50000000; // Default or configurable timeout value
    const timeoutValueNumeric = Number(timeoutValue);

    if (this.shouldNotAddHeaders(request)) {
      return this.handleRequestWithoutHeaders(request, next, timeoutValueNumeric);
    } else {
      return this.handleRequestWithHeaders(request, next, timeoutValueNumeric);
    }
  }

  private shouldNotAddHeaders(request: HttpRequest<any>): boolean {
    return apiWithoutHeader.includes(request.url as never);
  }

  private handleRequestWithoutHeaders(request: HttpRequest<any>, next: HttpHandler, timeoutValue: number): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      takeUntil(this.cancelRequestService.onCancelPendingRequests()),
      timeout(timeoutValue),
      tap(event => this.handleTap(event)),
      catchError(error => this.handleError(error, request, next))
    );
  }

  private handleRequestWithHeaders(request: HttpRequest<any>, next: HttpHandler, timeoutValue: number): Observable<HttpEvent<any>> {
    const modifiedRequest = this.addToken(request);
    return next.handle(modifiedRequest).pipe(
      takeUntil(this.cancelRequestService.onCancelPendingRequests()),
      timeout(timeoutValue),
      tap(response => this.handleResponse(response)),
      catchError(error => this.handleError(error, request, next))
    );
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.getAccessToken();
    const accessToken = token;
    if (accessToken) {
      return request.clone({
        headers: request.headers.set('Authorization', `${accessToken}`)
      });
    }
    return request;
  }

  // private getUserData(): any {
  //   const userData = sessionStorage.getItem('token');
  //   return userData ? JSON.parse(userData) : null;
  // }
  private getAccessToken(): any {
    const token = localStorage.getItem('token');
    return token ? token : null;
  }

  private handleTap(event: any): void {
    return event;
  }

  private handleResponse(response: any): void {
    if (response.type !== 0) {
      const token = response.headers.get('Authorization');
      if (token) {
        localStorage.setItem('hashToken', token.split(' ')[1]);
      }
    }
  }

  private handleError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 400:
          return this.handle400Error(error);
        case 401:
          return this.handle401Error(error);
        case 403:
          return this.handle403Error(error);
        case 404:
          return this.handle404Error(error);
        case 409:
          return this.handle409Error(error);
        case 500:
          return this.handle500Error(error);
        default:
          return throwError(error);
      }
    } else {
      return throwError(error);
    }
  }

  private handle401Error(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (error.status === 401) {
      this.auth.logout();
      return throwError(error);
    }
    return throwError(error);
  }

  private handle400Error(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    // this.toastService.showError(error.statusText, error.status);
    return throwError(error);
  }

  private handle403Error(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    this.modalService.dismissAll();
    // this.toastService.showError(error.error.Errors?.message || 'Access Forbidden', "Error");
    this.router.navigate(['/']);
    return throwError(error);
  }

  private handle404Error(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    this.toast.showError("Error", error.error.Errors?.message || 'Not Found');
    this.auth.logout();
    return throwError(error);
  }

  private handle409Error(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    return throwError(error);
  }

  private handle500Error(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    return throwError(error);
  }
}
