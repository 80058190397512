import { Routes } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';



export const CommonLayout_ROUTES: Routes = [
    {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',    
   
  },
  {
    path: 'organization',
    loadChildren: () => import('../../admin/organization/organization.module').then(m => m.OrganizationModule),
    // canActivate:[AuthGuard]
  },
 
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('../../user/dashboard-user/dashboard-user.module').then(m => m.DashboardUserModule),
  //   canActivate:[AuthGuard]
  // },
  {
    path: 'dashboard',
    loadChildren: () => import('../../admin/school-management/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate:[AuthGuard]
  },

  {
    path: 'branches-user',
    loadChildren: () => import('../../user/branches-user/branches-user.module').then(m => m.BranchesUserModule),
    canActivate:[AuthGuard]
  },

  // School Management

  {
    path: 'manage-class',
    loadChildren: () => import('../../admin/school-management/manage-class/manage-class.module').then(m => m.ManageClassModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'manage-student',
    loadChildren: () => import('../../admin/school-management/manage-student/manage-student.module').then(m => m.ManageStudentModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'attendance',
    loadChildren: () => import('../../admin/school-management/attendance/attendance.module').then(m => m.AttendanceModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'manage-gateway',
    loadChildren: () => import('../../admin/school-management/manage-gateway/manage-gateway.module').then(m => m.ManageGatewayModule),
    canActivate:[AuthGuard]
  }

];
